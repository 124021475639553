import { Divider, Flex } from '@chakra-ui/react';
import React from 'react';
import { IconButton, Tooltip } from '@chakra-ui/react';
import { FaInstagram, FaGithub, FaLinkedin } from 'react-icons/fa'

function SocialPanel(props) {
	return (
		<Flex
			w='20' h='100%'
			position='fixed'
			justify='flex-start'
			align='center'
			direction='column'
			// bgGradient="linear(to-t, #f04b5b, #0a66c2)"
		>
			<Tooltip
				label="Click to explore my Instagram!"
				placement='right'
				closeDelay={200} hasArrow arrowSize={10}
			>
				<IconButton
					color='#f04b5b'
					isRound="true"
					bgColor='inherit'
					icon={<FaInstagram />}
					_hover={{ bgColor:'inherit' }}
					onClick={() => window.open('https://www.instagram.com/kevvaria/')}
				/>
			</Tooltip>
			<Tooltip
				label="Click to explore my Github!"
				placement='right'
				closeDelay={200}
				hasArrow arrowSize={10}
			>
				<IconButton
					color='white'
					isRound="true"
					bgColor='inherit'
					icon={<FaGithub />}
					_hover={{ bgColor:'inherit' }}
					onClick={()=> window.open('https://github.com/kevvaria')}
				/>
			</Tooltip>
			<Tooltip
				label="Click to explore my LinkedIn!"
				placement='right'
				closeDelay={200}
				hasArrow arrowSize={10}
			>
				<IconButton
					isRound="true"
					color='#0a66c2'
					bgColor='inherit'
					icon={<FaLinkedin />}
					_hover={{ bgColor:'inherit' }}
					onClick={()=> window.open('https://www.linkedin.com/in/keval-varia')}
				/>
			</Tooltip>
			<Divider
				h='80'
				orientation='vertical'
			/>
		</Flex>
	);
}

export default SocialPanel;