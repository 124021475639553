import { Accordion, AccordionItem, AccordionButton, AccordionIcon, AccordionPanel, Flex, Tooltip } from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';
import courseList from '../courseList';
import { Badge, Box, Heading, Text } from '@chakra-ui/layout';


function Course() {
    
    const [courses, setCourses] = useState([])
    useEffect(() => {
        setCourses(courseList)
    }, []);

    return (
        <Flex
			direction="column"
			justify="space-around"
			align="center"
			minW="50%"
            py="5"
			color='whitesmoke'
			borderRadius={{base: '0', lg: 'lg'}}
            fontFamily='Roboto Mono'
            >
            <Tooltip label="List of my top academic courses!" placement='left' closeDelay={500} hasArrow arrowSize={10}>
                <Heading as="h1" size="lg" alignSelf="center" id="classes" p="10" fontFamily='inherit'>
                    Academics
                </Heading>
            </Tooltip>
            <Accordion allowMultiple w="70%" minw="50%" maxW="85%">
            {courses.map(x => {
                return (
                <AccordionItem>
                    <AccordionButton _expanded={{ bgGradient:"linear(to-r, blue.300, #171923)", textColor: "black"}}>
                        <Box flex="1" textAlign="left">
                            <Heading as="h3" size="sm" fontFamily='inherit'>
                            {x.name}
                            </Heading>
                        </Box>
                        <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel w="100%" pb={5}>
                        <br></br>
                        <Text align="left" fontFamily='inherit'>
                            {x.description}
                        </Text>
                        <Text fontFamily='inherit'>
                            {x.language.map(i => {
                                return <Badge px="2" ml="2" bg="slategray" color="whitesmoke" variant="subtle">{i}</Badge>;
                            })}
                        </Text>
                    </AccordionPanel>
                </AccordionItem>
                );
            })}
        </Accordion>
        </Flex>
    )
}

export default Course;