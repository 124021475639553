import React from 'react'
import { Button, Flex, Tooltip } from '@chakra-ui/react';
import { Text } from '@chakra-ui/layout'
import { MdEmail } from 'react-icons/md'

function Footer() {
    return (
        <Flex flexDirection="row" align="baseline" justifyContent="space-around" py="5">
            <Text size="sm" color="slategray" fontFamily='Roboto Mono' fontWeight='light'>Copyright 2021 Keval Varia</Text>
            <Flex justify="flex-end">
                <Tooltip label="Send me an Email">
                    <Button px="5" leftIcon={<MdEmail />} colorScheme="teal" variant="solid" fontFamily='Julius Sans' fontWeight='medium'>
                        <a href='mailto:developer@kevalvaria.com'>Email Me</a>
                    </Button>
                </Tooltip>
            </Flex>
        </Flex>
    )
}

export default Footer
