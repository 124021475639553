import { Flex } from '@chakra-ui/react'
import React from 'react'
import { Text } from '@chakra-ui/layout';
import { Image } from '@chakra-ui/image';
import photo from '../images/keval.jpg'
function Header() {
	return (
		<Flex w="100%" flexDirection="row" justify="center" alignItems="center" py="20" color='white'>
			<Flex flexDirection="column" spacing={10} m={50} fontFamily='Roboto Mono'>
				<Text fontSize="xl">
					Page is currently under <Text fontSize="4xl" textColor='blue.300'>reconstruction</Text>.
				</Text>
			</Flex>
		</Flex>
	)
}

export default Header
