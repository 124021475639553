import { Accordion, AccordionItem, AccordionButton, AccordionIcon, AccordionPanel, Flex, Tooltip } from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';
import { Badge, Box, Heading, Text } from '@chakra-ui/layout';
import certificationList from '../certificationList';

function Certification() {
    const [certifications, setCerts] = useState([])
    useEffect(() => {
        setCerts(certificationList)
    }, []);
    return (
        <Flex
			direction="column"
			justify="space-around"
			align="center"
			minW="50%"
			py="5"
			color='whitesmoke'
			fontFamily='inherit'
			borderRadius={{base: '0', lg: 'lg'}}
		>
		<Tooltip label="Click on the accordian panels below to view my certifications" placement='top' closeDelay={500} hasArrow arrowSize={10}>
			<Heading as="h1" size="lg" alignSelf="center" id="classes" pb="10" fontFamily='Roboto Mono'>
			Certifications
			</Heading>
		</Tooltip>
		<Accordion allowMultiple w={{base:'30%', sm:'70%', md:'50%', lg:'30%'}}>
		{certifications.map(x => {
			return (
			<AccordionItem>
				<AccordionButton _expanded={{ bgGradient:"linear(to-tr, blue.300, #171923)", color: "black"}}>
					<Box flex="1" textAlign="left">
						<Heading as="h3" size="sm" fontFamily='inherit'>{x.type}</Heading>
					</Box>
					<AccordionIcon />
				</AccordionButton>
				<AccordionPanel w="100%" pb={30}>
					<br></br>
					<Text>
						<Badge mb="5" variant="subtle" bg="slategray" color="whitesmoke">{x.status}</Badge>
					</Text>
					<Text fontFamily='Roboto Mono'>
						{x.description.map(i => {
							return <Text size="3xl" py="2">{i}</Text>;
						})}
					</Text>
				</AccordionPanel>
			</AccordionItem>
			);
        })}
      </Accordion>
    </Flex>
    )
}

export default Certification;
