import React from 'react';
import {
  ChakraProvider,
  Flex,
  SimpleGrid,
} from '@chakra-ui/react';
import Header from './components/header';
import Resume from './components/resume';
import Course from './components/course';
import Footer from './components/footer';
// import Navbar from './components/navbar';
import Certification from './components/certification';
import SocialPanel from './components/socialpanel';

function App() {
  	return (
		<ChakraProvider>
			<Flex
				w='100%'
				justify="center"
				flexDirection="column"
				bgColor="#171923"
				fontFamily='Noto Sans Mono'
			>
				//<SocialPanel/>
				<Header/>
				<Footer/>
			</Flex>
		</ChakraProvider>
  	);
}

export default App;
